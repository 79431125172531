/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "about-hearing-loss-in-arizona",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#about-hearing-loss-in-arizona",
    "aria-label": "about hearing loss in arizona permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "About Hearing Loss in Arizona"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To outsiders, Arizona is often associated with mesas and deserts – quiet places where loud noises are very rare. But the people of the state are actually exposed to plenty of noise, putting their hearing at risk. If you live in the Copper State, you’ll surely appreciate the value of clear hearing."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Imagine being in the State Parks or locations like the Grand Canyon and not being able to hear the subtle sounds of the desert (or worse, imagine having the constant throb of tinnitus to ruin the silence). And what would it be like to cheer on the Cardinals in the NFL or the Diamondbacks in Major League Baseball, without the ability to hear the roar of the fans or the crack of the bat as it strikes a home run?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Wherever you are in Arizona, being able to hear makes life richer and more enjoyable – so give yourself the best chance of protecting your hearing into old age by finding the right hearing aid and avoiding dangerous noise sources wherever possible."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "you-might-be-surprised-by-how-easy-it-is-to-damage-your-hearing-in-arizona",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#you-might-be-surprised-by-how-easy-it-is-to-damage-your-hearing-in-arizona",
    "aria-label": "you might be surprised by how easy it is to damage your hearing in arizona permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "You might be surprised by how easy it is to damage your hearing in Arizona"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There is certainly no shortage of ways to harm your hearing in the State of Arizona. For one thing, there are the state’s highways. Most of us rely on automobiles to get around the state. It’s a big place, after all, and cities like Flagstaff, Tucson and Phoenix are hundreds of miles apart."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But constant highway driving exposes us to dangerous noise levels, especially over a few decades. Then there’s shooting. Arizonans love to hunt for bobcat, deer and elk, and hundreds of thousands also regularly practice at local ranges. Most of the time, they use protective gear to guard against the 140 decibels generated by an average gunshot. But recent studies have found that almost 40 percent of range shooters go without protective gear at times, along with a massive 95 percent of hunters. And that’s not all Arizonans’ ears need to worry about."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For some people, work is the culprit. For instance, military bases like Fort Huachuca or the Yuma Proving Ground are full of noise from vehicles and weaponry. Even a few years of attending music concerts or sports events can damage your hearing. You don’t need to attend every punk gig at Phoenix’s Crescent Ballroom to experience hearing loss. Ordinary live music can generate plenty of noise, while full-on crowd noise can top 130-140 decibels at NFL matches."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "understanding-arizonas-hearing-loss-issues---the-statistics",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#understanding-arizonas-hearing-loss-issues---the-statistics",
    "aria-label": "understanding arizonas hearing loss issues   the statistics permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Understanding Arizona's hearing loss issues - the statistics"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "To give you a better idea of how serious hearing loss is in Arizona (and how much work needs to be done to help Arizonans improve their hearing), it helps to run through a few key stats. Arizona has a population of just over 7 million, with around 5 million adults. Across the USA, about 14 percent of those aged 20-69 report experiencing some degree of hearing loss, which adds up to approximately 200,000 Arizonans."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "However, when you add in seniors (and scorching hot Phoenix has a lot of seniors), the numbers rise significantly. Nationwide, about 25 percent of those aged 65-74 have “disabling” hearing loss, and 50 percent of those over 75. So around 400,000 Arizonans are suffering from hearing loss of some form – and maybe more."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But are they doing everything they can to prevent it and treat it? Probably not. One of the most shocking hearing loss statistics is that 16 percent of Americans who can benefit from hearing aids actually use them. That means there are hundreds of thousands of Arizona residents who refuse to seek out the best auditory technology."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If they knew what modern hearing aids can do, they would almost certainly change their minds."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-hearcom-works-to-improve-arizonas-hearing-loss-situation",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-hearcom-works-to-improve-arizonas-hearing-loss-situation",
    "aria-label": "how hearcom works to improve arizonas hearing loss situation permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How hear.com works to improve Arizona's hearing loss situation"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "At hear.com, we’re big believers in the power of technology to improve the lifestyles of people with hearing loss of all forms. Much of the time, hearing loss can respond well to hearing aids, and tinnitus can be alleviated as well. Modern devices have come a long way, processing conversations, streaming TV and music, and carrying more battery charge than ever."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "But how do you choose the right hearing aid? The best way is to work with our network of more than 80 Partner Providers across Arizona. All of them are expert professionals with an in-depth knowledge of the causes and treatment of hearing loss, so use their expertise to your advantage."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "how-to-get-in-touch-with-a-hearcom-expert",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#how-to-get-in-touch-with-a-hearcom-expert",
    "aria-label": "how to get in touch with a hearcom expert permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "How to get in touch with a hear.com expert"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Starting the process of purchasing a cutting-edge hearing aid couldn’t be any easier. We’ve created a questionnaire on the hear.com site which lets you enter all of the information we need to reserve a slot for a risk-free trial. After that, you’ll be able to speak with a hear.com team member, who can go over all your hearing aid options."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "They will book an appointment with an audiologist or hearing specialist near you to check your hearing and fit the right hearing aids for you. Our process is a hassle-free way to improve your hearing, so contact us today. We want you to enjoy all of the wonderful music, atmosphere and conversation that Arizona has to offer."), "\n", React.createElement(LandingPageCta, {
    copy: "Try hearing aids near you",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
